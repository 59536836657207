<template>
    <div style="min-height:100vh;background:#fff">
        <div style="width:100%;height:50px;"></div>
        <div class="content_title">手机号码<span style="color:red;">*</span></div>
        <div class="content_input">
            <input @blur="onBlur" v-model="mobile" placeholder="请输入您的手机号码" maxlength="11" type="tel" />
            <div style="white-space: nowrap;color:#0ABBB9" @click="getCode">{{ time == 0 ? '获取验证码' : time + '秒'}}</div>
        </div>
        <div class="content_title">验证码<span style="color:red;">*</span></div>
        <div class="content_input">
            <input @blur="onBlur" v-model="code" placeholder="请输入验证码" maxlength="6" type="tel" />
        </div>
        <!-- <div class="content">
            <div class=""></div>
            <div class="cell">
                <div class="left">
                    <div class="must">*</div>
                    <div class="title">手机号码</div>：
                </div>
                <div class="right">
                    <input @blur="onBlur" v-model="mobile" placeholder="请输入您的手机号码" maxlength="11" type="tel" />
                    <div style="white-space: nowrap;color:#4c94f6" @click="getCode">{{ time == 0 ? '获取验证码' : time + '秒'}}</div>
                </div>
            </div>
            <div class="cell">
                <div class="left">
                    <div class="must">*</div>
                    <div class="title">验证码</div>：
                </div>
                <div class="right">
                    <input @blur="onBlur" v-model="code" placeholder="请输入验证码" maxlength="6" type="tel" />
                </div>
            </div>
        </div> -->
        <div class="btn_sty" @click="save"  :class="is_click ? 'd8' : ''">确认</div>
        <!-- 底部 -->
        <div class="footer-bottom"></div>
        <!-- <div class="footer">
            <div @click="save" :style="is_click ? 'background:#aaa' : ''">确认</div>
        </div> -->
        <!-- 弹窗 -->
        <div class="cancl_tan" v-if="my_lizhi">
            <div class="cancl_tan_on">
                <div class="cancl_tan_on_title">绑定手机号码</div>
                <div class="cancl_tan_on_cont">
                    <div class="cancl_tan_on_cont_ont">该手机号码已绑定其他微信，确认换绑到本微信？</div>
                </div>
                <div class="cancl_tan_on_bont">
                    <div class="cancl_tan_on_bont_lt" @click="cancle_no">取消绑定</div>
                    <div class="cancl_tan_on_bont_rt" @click="cancle_ok">确认换绑</div>
                </div>
            </div>
        </div>
        <!-- 弹窗 -->
        <div class="bottom_back">
            <div class="bottom_back_on" @click="gobanck">
                <div class="bottom_back_wenz">返回</div>
                <div class="iconfont icon-fanhui"></div>
            </div>
        </div>
        <!-- 弹窗 -->
    </div>
</template>
<script>
import '@/assets/css/index.css'
// import { send_sms_get } from "@/api/login";
// import { getInfo, postInfo, mobile_bind } from "@/api/home";
import { Toast } from 'vant';
export default {
    data() {
        return {
            time: 0, // 倒计时
            details: {},
            my_lizhi: false,
            mobile: '', // 手机号码
            code: '', // 验证码
            is_form: 'home', // home，apply: 从home，apply过来的要请求接口，info：过来的不需要请求接口
            is_click: false
        }
    },
    // 判断是否是从借支列表过来的，是的话则返回上一页，不是则跳转到home页面
    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (from.name == 'MyInfo') {
                vm.is_form = 'info';
            } else if (from.name == 'RecruitmentApply' || from.name == 'RecruitmentDetails' || from.name == 'recruitmentDetails') {
                vm.is_form = 'apply';
            } else {
                vm.is_form = 'home';
            }
        });
    },
    mounted() {
        this.$store.commit("getWxSDK");
        window.scrollTo(0, 0)
        this.$store.commit("hideTabbar");
        if (this.$route.params.details) {
            this.details = JSON.parse(this.$route.params.details)
        } else {
            this.getInfo()
        }
    },
    methods: {
        gobanck() {
            this.$router.go(-1);
        },
        //取消
        cancle_no() {
            this.my_lizhi = false;
        },
        cancle_ok() {
            this.Request('mobile_bind', 'put', { new_mobile: this.mobile }).then(res => {
                if (res.status == 0) {
                    this.my_lizhi = false;
                    this.details.mobile = this.mobile
                    this.$router.go(-1)
                }
            })
        },
        getInfo() {
            this.Request('client/mine/info', 'get', { team_id: localStorage.getItem('team_id') }).then(res => {
                if (res.status == 0) {
                    this.details = res.data.basic
                }
            })
        },
        // 返回上一页
        onClickLeft() {
            if (this.is_form == 'info') {
                this.$router.replace({ name: 'MyInfo', params: { details: JSON.stringify(this.details) } })
            } else {
                this.$router.go(-1)
            }
        },
        // 解决ios端的键盘弹起来后会把页面顶上去，导致点击其他元素（尤其是弹出框）的效果失效
        onBlur() {
            setTimeout(function () {
                let scrollHeight =
                    document.documentElement.scrollTop || document.body.scrollTop || 0;
                window.scrollTo(0, Math.max(scrollHeight - 1, 0));
            }, 100);
        },
        getTel() {
            if (!!this.mobile == false) {
                Toast('请输入手机号码')
                return false
            }
            if (!(/^[1]([3-9])[0-9]{9}$/.test(this.mobile))) {
                Toast('请输入正确的手机号码')
                return false
            }
            if (this.details && this.details.mobile) {
                if (this.mobile == this.details.mobile) {
                    Toast('不可以换绑自己的手机号')
                    return false
                }
            }
            return true
        },
        // 获取验证码
        getCode() {
            if (this.getTel() == true && this.time <= 0) {
                var codeTime = 60
                this.time = codeTime
                var that = this
                var timer = setInterval(() => {
                    if (codeTime == 0) {
                        clearInterval(timer)
                    } else {
                        codeTime--
                        that.time = codeTime
                    }
                }, 1000);
                var data = {
                    mobile: this.mobile,
                    option: 'verify'
                }
                this.Request('client/sms', 'get', data).then(res => {
                    if (res.status == 0) {
                        Toast.success('发送成功')
                    }
                })
            }
        },
        save() {
            if (this.getTel() == true) {
                if (!!this.code == false) {
                    Toast('请输入验证码')
                    return
                }
                if (this.is_click) return
                this.is_click = true
                var data = {
                    jobseeker_mobile: this.mobile,
                    mobile_verify_code: this.code,
                    option: 'verify'
                }
                this.Request('client/mine/info', 'post', data).then(res => {
                    this.is_click = false
                    if (res.status == -10) { // 换绑手机号码
                        this.my_lizhi = true;
                        // Dialog.confirm({
                        //     title: '绑定手机号码',
                        //     message: '该手机号码已绑定其他微信，确定换绑到本微信？',
                        // })
                        //     .then(() => {
                        //         // on confirm
                        //         this.Request('mobile_bind', 'put', { new_mobile: this.mobile }).then(res => {
                        //             if (res.status == 0) {
                        //                 this.details.mobile = this.mobile
                        //                 // 是否是从立即报名过来的
                        //                 if (this.is_form == 'apply') { // 是？跳转到填写报名信息页面
                        //                     this.$router.replace({ path: '/recruitmentApply', query: { rid: this.$route.query.rid } })
                        //                 } else if (this.is_form == 'info') {
                        //                     this.$router.replace({ name: 'MyInfo', params: { details: JSON.stringify(this.details) } })
                        //                 } else { // 返回上一页
                        //                     this.$router.go(-1)
                        //                 }
                        //             }
                        //         })
                        //     })

                    } else if (res.status == 0) {
                        this.details.mobile = this.mobile
                        this.$router.go(-1)
                    }
                })
            }

        }
    }
}
</script>
<style scoped>
.content_input {
    width: calc(100% - 80px);
    border-bottom: 2px solid #f8f8f8;
    height: 70px;
    margin-left: 40px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
}
.content_input input {
    width: 75%;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 0;
    background: transparent;
}
.content_title {
    width: calc(100% - 80px);
    margin-left: 40px;
    margin-bottom: 22px;
    height: 40px;
    line-height: 40px;
    text-align: left;
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #1a1a1a;
}
.btn_sty {
    width: calc(100% - 80px);
    margin: 120px auto;
    height: 80px;
    line-height: 80px;
    text-align: center;
    background: #0abbb9;
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    opacity: 1;
    border-radius: 10px;
}
.content {
    /* padding-top: 110px; */
    margin: 0 30px;
}
/* input输入框样式 */
.cell {
    display: flex;
    border-bottom: 2px solid #f8f8f8;
    padding: 18px 0;
    position: relative;
}
.cell .left {
    display: flex;
    width: 28%;
}
.cell .left .must {
    color: red;
    margin: 6px 10px 0 0;
    width: 10px;
}
.cell .left .title {
    min-width: 132px;
    text-align-last: justify;
}
.cell .right {
    width: 75%;
    display: flex;
    justify-content: space-between;
}
.cell .right input {
    width: 75%;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 0;
    background: transparent;
}
.cell .right img {
    width: 30px;
    height: 30px;
    vertical-align: middle;
}
</style>